<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		class: string;
		width: string;
		height: string;
	}>(),
	{
		class: '',
		width: '20',
		height: '20',
	},
);
</script>
<template>
	<svg
		:width="props.width || 20"
		:height="props.height || 20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		:class="props.class"
	>
		<g id="Frame 1000002082">
			<g id="Group 410792">
				<path
					id="Vector"
					d="M15.0858 8.30093C15.0538 8.1731 14.9366 8.09854 14.7662 8.09854H11.1017L13.7595 2.43675C13.8021 2.3462 13.8554 2.20772 13.7968 2.1012C13.7595 2.03728 13.6903 2 13.6051 2C13.4346 2 13.2961 2.14381 13.2429 2.19707L9.39733 6.2024L6.80878 8.88149L5.17363 10.5806C5.03515 10.7244 4.97656 10.8788 5.00852 11.012C5.04048 11.1451 5.15765 11.2197 5.32809 11.2197H8.40665H8.86471L8.68362 11.6245L5.99387 17.5686C5.95126 17.6591 5.90332 17.7923 5.96191 17.8988C5.9992 17.9627 6.06844 18 6.14833 18C6.3241 18 6.46791 17.8455 6.51052 17.7976L10.1909 13.8349L12.7369 11.0919L14.926 8.73236C15.0644 8.58322 15.1177 8.42876 15.0858 8.30093Z"
					fill="#FFB749"
				/>
			</g>
		</g>
	</svg>
</template>
